const BreakPoints = {
  xs: 0,
  galaxFold: 280,
  sm: 576,
  md: 768,
  lg: 992,
  loginLayoutPoint: 1120,
  xl: 1200,
  xxl: 1400,
  logoHidePoint: 500,
};

export default BreakPoints;
