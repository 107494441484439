const Colors = {
  black: '#000000',
  black2: '#333334',
  black3: '#313137',
  black4: '#303034',
  black5: '#27272A',
  black6: '#404047',
  black7: '#171718',
  white: '#ffffff',
  white2: '#F8F6F0',
  grey: '#7D8D95',
  greyThin: '#9599A5',
  grey1: '#E5E6E9',
  grey2: '#808184',
  grey3: '#F2F2F2',
  grey4: '#D9D9D9',
  grey5: '#F3F3FB',
  grey6: '#717179',
  grey7: '#ABACB6',
  grey8: '#DCDCE1',
  grey9: '#EEEEF3',
  grey10: '#f5f5f5',
  grey11: '#8C8C8C',
  green1: '#00D387',
  green2: '#D6F6EB',
  orange1: '#FCA119',
  orange2: '#FFEED4',
  orange3: '#EB920D',
  navy: '#E1F1F8',
  navy1: '#056790',
  primary: '#ed9617',
  secondary: '#1b1e23',
  secondaryHover: '#1b1e23',
  title: '#3B3F46',
  subTitle: '#626671',
  success: '#34c38f',
  branding: '#FC0006',
  branding100: '#880808',
  branding2: '#F11E51',
  fadingBlack: 'rgba(0, 0, 0, 0.5)',
  fadingBlack2: 'rgba(0, 0, 0, 0.6)',
  backgorund: '#27272A',
  grayScale90: '#404047',
  black10: '#171718',
  brandingGray: '#DD5F62',
  branding10: '#C7080D',
  grayScale80: '#D0D0D0',
  grayScale70: '#303034',
  grayScale60: '#4D4D57',
  grayScale50: '#717179',
  grayScale40: '#ABACB6',
  grayScale30: '#DCDCE1',
  grayScale20: '#EEEEF3',
  grayScale10: '#F8F6F0',
  success10: '#D6F6EB',
  success20: '#55C914',
  success30: '#00D387',
  failed10: '#FFD8E1',
  failed20: '#F11E51',
  whiteMask: '#ACACAE',
  brandingMask: '#9B2621',
  blueGray: '#056790',
  chartBg: '#0BFFFF',
  red1: '#FFAEAF',
  red2: '#FD262B',
  red3: '#730003',
  red4: '#C10005',
  red5: '#FF4B50',
  red6: '#530103',
  primaryHoverRed: '#DE0005',
  buttonDisable: '#F56E72',
};

export default Colors;
