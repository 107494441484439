export const MenuKeys = {
  dashboard: 'menu-dashboard',
  events: 'menu-events',
  eventDetail: 'menu-event-detail',
  users: 'menu-users',
  userDetail: 'menu-user-detail',
  tickets: 'menu-tickets',
  settings: 'menu-settings',
  profile: 'menu-profile',
  userAndPermissions: 'menu-userAndPermissions',
  transactions: 'menu-transactions',
  orders: 'menu-orders',
  ticketsSold: 'menu-ticketsSold',
  organisers: 'menu-organisers',
};
