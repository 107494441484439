import { SiderTheme } from 'antd/lib/layout/Sider';

export const LgWidth = 992;
export const SidebarWidth = 240;
export const CollapsedWidth = 80;
export const HeaderHeight = 64;
export const LayoutTypes = {
  veritical: 'veritical',
  horizontal: 'horizontal',
};
export const SidebarTypes = {
  default: 'default',
  condensed: 'condensed',
  icon: 'icon',
};
export const SidebarTheme: SiderTheme = 'dark';
export const SidebarThemes = {
  dark: 'dark',
  light: 'light',
};
export const TopbarThemes = {
  dark: 'dark',
  light: 'light',
};
